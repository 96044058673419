import moment from 'moment'
import get from 'lodash/get'
import _ from 'lodash'
import $ from 'jquery'

// UTILS
import {
  currentVehicleType, objectToFormData, isCustomerEditBooking, convertCustomReimbursementToParam, isCustomerEditPickupTime,
} from 'utils/new_booking/common'
import { Utils } from 'utils/Utils'
import { TallyUtils } from 'utils/booking/TallyUtils'
import { CPODUtils } from 'utils/booking/CPODUtils'
import toastr from 'utils/toast';
// API
import BookingAPI from 'api/bookings'
import CustomerAPI from 'api/customers'
import AwsImageUpload from 'api/awsImageUpload'
import SubAccountAPI from 'api/subAccountTag'
// ACTIONS
import { checkDiscountCode, showPopupWhenDiscountInvalid } from '../new_booking/discountCodeActionCreators'
import { clearExtraRequirementLocations, updateLocation } from '../new_booking/locationActionCreators'
import { updateCurrentPopupID } from 'store/actions/new_booking/currentPopupIDActionCreators'
import I18n from 'i18n/i18n'

// COMPONENTS
import { updateBatchBookingAttrs } from '../batch_management/batchManagemenActionCreators'
// CONSTANTS
import {
  LONG_HAUL,
  FULL_DAY,
  STATUS_CANCELLED,
  NOW,
  TEN_SECONDS,
  ONE_MIN_TO_MS,
  PAYMENT_PENDING,
  STATUS_CS_FINDING_DRIVER,
  STATUS_ASSIGNING_DRIVER_TIMEOUT,
  STATUS_DRIVER_DECLINED_BOOKING,
  STATUS_LOCATING_DRIVER,
  STATUS_LOCATING_DRIVER_TIMEOUT,
  RECOVERY_STATUS_DECLINE_RECOVERY,
  CUSTOMER_PAYMENT,
  SECTION_TYPE_PAYMENT_LIST,
  CUSTOMER_PAYMENT_STATUS,
  SPECIAL_SETTINGS_AREA,
  METHOD_CASH,
  FAILED_PAYMENT_STATUS,
  BOOKING_FAILED_STATUS,
  GET_BOOKING_SUCCESS,
} from 'constants/bookingConstants'
import {
  DEVINA_POPUP_TYPE,
  POPUP_DEVINA,
  POPUP_PENALTY_FEE,
} from 'constants/common/popupConstants'
import {
  CANCELED,
  DRIVER_PREFERENCES
} from 'constants/newBookingConstants'
import { DISCOUNT_CODE_STATUS } from 'constants/discountCodeConstants'
import { SECTION_TYPE, TRACKING_UPDATE_DRIVER_DURATION_INTERVAL } from 'constants/trackingBookingConstants'

import { isBookAgain, isEditBooking, isNewDriver, isRetryAssigned, setAttachments } from 'utils/booking/common'
import LocationAPI from 'api/locations'
import { attachmentsActionsCreator } from 'store/toolkit/newBooking/attachments.reducer'
import { bookingAgainDetailsActionsCreator } from 'store/toolkit/newBooking/bookingAgainDetails.reducer'
import { dataChangesActionsCreator } from 'store/toolkit/newBooking/dataChange.reducer'
import { documentReturnActionsCreator } from 'store/toolkit/newBooking/documentReturn.reducer'
import { othersActionsCreator } from 'store/toolkit/newBooking/others.reducer'
import { roundTripDiscountActionsCreator } from 'store/toolkit/newBooking/roundTripDiscount.reducer'
import { subAccountTagPickedActionsCreator } from 'store/toolkit/subAccountTagPicked/subAccountTagPicked.reducer'
import { timeTypeActionsCreator } from 'store/toolkit/newBooking/timeType.reducer'
import { selectedVehicleTypeIDActionsCreator } from 'store/toolkit/newBooking/selectedVehicleTypeID.reducer'
import { selectedServiceTypeIDActionsCreator } from 'store/toolkit/newBooking/selectedServiceTypeID.reducer'
import { bookingActionsCreator } from 'store/toolkit/bookings/booking.reducer'
import { checkSubAccountActionsCreator } from 'store/toolkit/checkSubAccount/checkSubAccount.reducer'
import { typeBooking } from 'constants/CommonConstant'
import { bookingsActionsCreator } from 'store/toolkit/bookings/bookings.reducer'
import { requireSignaturesActionsCreator } from 'store/toolkit/requireSignatures/requireSignatures.reducer'
import { subAccountTagListActionsCreator } from 'store/toolkit/subAccountTagList/subAccountTagList.reducer'
import { currentStepActionsCreator } from 'store/toolkit/currentStep/currentStep.reducer'
import { pickupTimeActionsCreator } from 'store/toolkit/newBooking/pickupTime.reducer'
import { timeTypeUIActionsCreator } from 'store/toolkit/newBooking/timeTypeUI.reducer'
import { quickChoiceActionsCreator } from 'store/toolkit/newBooking/quickChoice/quickChoice.reducer'
import { isNewDriverActionsCreator } from 'store/toolkit/newBooking/assignDriver/isNewDriver.reducer'
import { extraServicesActionsCreator } from 'store/toolkit/extraServices/extraServices.reducer'
import { discountCodeActionsCreator } from 'store/toolkit/newBooking/discountCode.reducer'
import { jobOrderNumberActionsCreator } from 'store/toolkit/newBooking/jobOrderNumber.reducer'

import {
  closeLoading, hideLoading, openLoading, showLoading
} from 'assets/javascripts/webapp-v2/common'
import { customerDriverActionsCreator } from 'store/toolkit/trackingBooking/customerDriver.reducer'
import { devinaActionsCreator } from 'store/toolkit/trackingBooking/devina.reducer'
import { extraInfosActionsCreator } from 'store/toolkit/extraInfos/extraInfos.reducer'
import PaymentIntegrationAPI from 'api/payment-integration'
import { updateExtraInfos } from './extraInfosCreators'
import SettingAPI from 'api/settings'
import { isEmpty } from 'lodash-es'
import { appsFlyerTrackEvent } from 'utils/trackingAppsFlyer'
import { CANCEL_BOOKING_PRESS_AFTER_DRIVER_ACCEPT, CANCEL_BOOKING_PRESS_BEFORE_DRIVER_ACCEPT } from 'constants/trackingAppsFlyer'
import store from 'store/store'
// ASSETS

// FOR BOOKING

function showError() {
  closeLoading()
  const ms = I18n.t('closures.confirm.rate_error')
  toastr.error(ms)
}

export const getSummaryBookingDetails = (id, cb = () => undefined) => async (dispatch) => {
  const includeParams = [
    'driver.vehicles',
    'locations',
    'service_type',
    'extra_requirements',
    'badges',
    'vehicle_type',
    'reimbursements',
    'fleet_partner',
    'custom_reimbursements',
    'sub_account_tag',
    'batch_id',
    'exceed_allowance_time',
    'banned_driver_ids',
    'favorite_driver_ids',
    'reference_type',
    'locations',
    'vehicle_type',
    'reimbursements',
  ]
  const result = await BookingAPI.getBookingDetail(id, includeParams)
  if(!_.isEmpty(result?.object)) {
    dispatch(bookingActionsCreator.bookingUpdate({
      booking: result?.object
    }))
    cb(result)
  }
  /* return request
    .get(`/api/v3/bookings/${booking.id}/tracking_info`)
    .set('Accept-Language', I18n.locale)
    .set('Authorization', currentCustomer.authentication_token)
    .set('Screen-Dpi', 'mdpi')
    .query({
      'include[]': includeParams,
    })
    .end((err, response) => {
      dispatch(({
        type: GET_BOOKING_SUCCESS,
        payload: response?.body?.object || {},
      }))
      cb()
    }) */
}

export const updateBookAgainDetails = bookAgainDetails => (dispatch) => {
  dispatch(extraServicesActionsCreator.setPreVehicleTypeId(bookAgainDetails.vehicle_type_id))
  dispatch(extraServicesActionsCreator.updateExtraServicesTimeType(bookAgainDetails.time_type))
  dispatch(bookingAgainDetailsActionsCreator.updateBookAgainDetails(bookAgainDetails))
}

export const updateBookAgainSettlementDetails = settlementDetails => bookingAgainDetailsActionsCreator.updateBookAgainSettlementDetails(settlementDetails)

export const updateRequireSignatures = value => requireSignaturesActionsCreator.updateRequireSignatures(value)

export const getBookingDetailAction = (callback = (() => { }), id) => async (dispatch, getState) => {
  const includeParams = ['driver.vehicles', 'locations', 'service_type', 'extra_requirements', 'badges', 'vehicle_type', 'reimbursements', 'fleet_partner', 'locations.reimbursements', 'custom_reimbursements', 'sub_account_tag', 'batch_id', 'exceed_allowance_time']
  const state = getState()
  const { booking } = state
  if (booking.tracking_token) {
    includeParams.push('tracking_token')
  }
  const params = {
    'include': includeParams,
  }
  const result = await BookingAPI.getBookingDetail(id || booking.id, params)
  if(!_.isEmpty(result?.object)) {
    dispatch(bookingActionsCreator.bookingUpdate({
      booking: result?.object
    }))
    
  }
  callback && callback()
}

export const getInfoMarketingBooking = (quoteID, callback = (() => { })) => async (dispatch, getState) => {
  const dataQuoteBooking = await BookingAPI.getBookingFromQuote(quoteID)
  if (_.isEmpty(dataQuoteBooking)|| dataQuoteBooking.error) {
    callback()
    return 
  }
  const { serviceTypes } = getState()
  const serviceTypeId = dataQuoteBooking?.service_type_id
  const vehicleTypeId = dataQuoteBooking?.vehicle_type_id
  const serviceType = serviceTypes.find(st => st.id === serviceTypeId) || serviceTypes.find(service => service.is_default) || serviceTypes[0]
  const vehicleType = serviceType?.vehicle_types?.find(vt => vt.id === vehicleTypeId) || serviceType?.vehicle_types?.[0]
  const bufferTime = (vehicleType?.settings?.buffer_time_for_pick_time_single || 0) * ONE_MIN_TO_MS
  const datetime = new Date((dataQuoteBooking?.pickup_time * TEN_SECONDS) + bufferTime).toString()

  dispatch(updateBookAgainDetails(dataQuoteBooking))
  dispatch(selectedServiceTypeIDActionsCreator.setSelectedServiceTypeId(serviceType.id))
  dispatch(selectedVehicleTypeIDActionsCreator.setSelectedVehicleTypeId(vehicleType.id))
  dispatch(timeTypeActionsCreator.changeTimeType(dataQuoteBooking?.time_type))
  dispatch(pickupTimeActionsCreator.changePickupTime(datetime))
  dispatch(roundTripDiscountActionsCreator.updateRoundTripDiscount(dataQuoteBooking?.round_trip_discount))
  dispatch(documentReturnActionsCreator.updateDocumentReturn(dataQuoteBooking?.booking_tracking))
  dispatch(requireSignaturesActionsCreator.updateRequireSignatures(dataQuoteBooking?.require_signatures))
  if (!_.isEmpty(dataQuoteBooking.booking_attachments)) {
    const attachmentsList = setAttachments(dataQuoteBooking.booking_attachments)
    dispatch(attachmentsActionsCreator.updateAttachments(attachmentsList))
  }
  callback()
}

export const getInfoEditBooking = (bookingId, isBookAgain, navigate, callback = () => undefined) => async (dispatch, getState) => {
  const currentStep = getState().currentStep
  const res = await BookingAPI.getBookingInfoForEdit(bookingId, isBookAgain, navigate)
  if(res.status === 500) {
    toastr.error(I18n.t('errors.normal.permission_denied'))
    window.location.href = '/'
  }
  const dataEditBooking = res?.data?.object
  if (_.isEmpty(dataEditBooking)) return
  const isNewDriverEdit = isNewDriver()
  let step = 1
  if (isEditBooking()) step = 1
  if (isRetryAssigned()) step = 3
  if (isNewDriverEdit) step = 2
  dispatch(jobOrderNumberActionsCreator.updateJobOrderNumber(dataEditBooking.job_order_number))
  dispatch(quickChoiceActionsCreator.changeQuickChoice(dataEditBooking.quick_choice_id))
  dispatch(timeTypeUIActionsCreator.changeTimeTypeUI(dataEditBooking.display_time_type))
  dispatch(timeTypeActionsCreator.changeTimeType(dataEditBooking?.time_type))
  const datetime = new Date(dataEditBooking.pickup_time * TEN_SECONDS).toString()
  dispatch(pickupTimeActionsCreator.changePickupTime(datetime))
  
  dispatch(selectedVehicleTypeIDActionsCreator.setSelectedVehicleTypeId(dataEditBooking?.vehicle_type_id))
  dispatch(selectedServiceTypeIDActionsCreator.setSelectedServiceTypeId(dataEditBooking?.service_type_id))
  
  dispatch(roundTripDiscountActionsCreator.updateRoundTripDiscount(dataEditBooking?.round_trip_discount))
  dispatch(documentReturnActionsCreator.updateDocumentReturn(dataEditBooking?.booking_tracking))
  dispatch(isNewDriverActionsCreator.setIsNewDriver(isNewDriverEdit))
  if (dataEditBooking?.discount_code_info && !isBookAgain) {
    dispatch(discountCodeActionsCreator.updateDiscountCode({ status: 'valid', value: dataEditBooking?.discount_code_info }))
  }
  if (isEditBooking()) {
    dispatch(requireSignaturesActionsCreator.updateRequireSignatures(dataEditBooking?.require_signatures))
  }
  if (currentStep) {
    dispatch(currentStepActionsCreator.changeStep(step))
  }
  if (!_.isEmpty(dataEditBooking.booking_attachments)) {
    const attachmentsList = setAttachments(dataEditBooking.booking_attachments)
    dispatch(attachmentsActionsCreator.updateAttachments(attachmentsList))
  }
  dispatch(updateBookAgainDetails(dataEditBooking))
  callback()
}

export const checkIfRedirectToDetail = (booking) => {
  const hasNotDriverAndFleet = !booking.driver_id && !booking.fleet_partner?.id
  if (hasNotDriverAndFleet || booking.canceled_by_driver) {
    if (booking.status === STATUS_CANCELLED) {
      localStorage.setItem('show_booking_canceled', true)
    }
    return true
  }
  return false
}

// prevent api loop
let time = moment()
export const getBookingTrackingInfo = (dataTracking) => async(dispatch, getState) => {
  const { followToken, callback, bookingId, forceCallApi, isCheckToRedirectDetail, trackingToken, storageDuration, isNotShowETA } = dataTracking || {}
  if (!forceCallApi && moment().isBefore(moment(time).add(7, 'seconds'))) {
    return
  }
  time = moment()

  const state = getState()
  const booking = state.booking
  const includeParams = [
    'tally_info', 'signature_url', 'vehicle', 'settlements', 'custom_reimbursements',
    'locations.tally_info', 'vehicle_type.minimum_pickup_time_schedule',
    'extra_requirement_locations.extra_requirement', 'extra_requirement_locations.extra_requirement']
  if (trackingToken) {
    includeParams.push('tracking_token')
  }
  const params = {
    'include': includeParams,
  }

  if(followToken) {
    params.follow_token = followToken
  } else if( trackingToken) {
    params.tracking_token= trackingToken
  }

  const res = await BookingAPI.getTrackingInfo(bookingId, params)
  let resCustomerBooking = {}
  delete params['include'];
  if(storageDuration <= TRACKING_UPDATE_DRIVER_DURATION_INTERVAL[6].duration || isNotShowETA) {
    resCustomerBooking = await CustomerAPI.getCustomerBooking(bookingId, { section_type: 'depart_info', ...params  })
  }

  if (followToken && res.status === 404) {
    dispatch(bookingActionsCreator.resetBooking())
    return
  }
  const newBooking = res?.data?.object
  if(!isEmpty(newBooking)) {
    const newDriverID = newBooking.driver?.id
    const driverID = booking?.driver?.id
    if (newDriverID !== driverID && driverID) {
      if (_.isUndefined(driverID)) {
        localStorage.setItem(`isChangeDriver-${bookingId}`, 0)
      } else {
        localStorage.setItem(`isChangeDriver-${bookingId}`, 1)
      }
      localStorage.setItem(`flag_${bookingId}`, 1)
    }

    if(!isEmpty(resCustomerBooking)) {
      resCustomerBooking?.locations?.forEach((location, index) => {
        newBooking.locations[index].departure_time_to_show_eta = location.departure_time
        newBooking.locations[index].signature_updated_at_to_show_eta = location.signature_updated_at
        newBooking.locations[index].arrived_at = location.arrived_at
      })
    } 

    if (isCheckToRedirectDetail && checkIfRedirectToDetail(newBooking)) {
      window.location.href = `/bookings/${newBooking.id}`
      return
    } else {
      dispatch(
        bookingActionsCreator.bookingUpdate({
          booking: { ...newBooking }
        })
      )
      callback && callback(newBooking)
    }
  }
}

export const retryBooking = (options = {}) => async (dispatch, getState) => {
  const { booking } = getState()
  const params = {
    id: booking.id,
    assign_driver_booking_attributes: options.driver_id
      ? {
        driver_id: options.driver_id,
        fleet_partner_id: options.fleet_partner_id
      }
      : undefined
  }

  showLoading()
  await BookingAPI.retry(params)
  hideLoading()
  dispatch(getBookingDetailAction())
}

export const cancelBooking = (paramBooking, callback) => async (dispatch, getState) => {
  const state = getState()
  const booking = paramBooking || state.booking
  const { currentCustomer } = state

  if (booking.status === STATUS_CANCELLED) {
    // eslint-disable-next-line
    dispatch(favorite(true))
      .then(response => callback && callback(null, response))
      .catch(error => callback && callback(error, null))
  } else {
    const params = { id: booking.id, save_booking: true }
    const res = await BookingAPI.cancelBooking(booking.id, params)
    const paramsTracking = {
      customer_id: currentCustomer.id,
      company_id: currentCustomer.current_company_id,
      booking_type: booking.booking_type,
    }

    if (booking.driver_id) {
      appsFlyerTrackEvent(CANCEL_BOOKING_PRESS_AFTER_DRIVER_ACCEPT, paramsTracking)
    } else {
      appsFlyerTrackEvent(CANCEL_BOOKING_PRESS_BEFORE_DRIVER_ACCEPT, paramsTracking)
    }
    window.localStorage.setItem('is_customer_cancel', true)
    callback(res?.data?.error, res)
  }
}

export const favorite = value => (dispatch, getState) => {
  const state = getState()
  return BookingAPI.favoriteBooking(state.booking.id, value, () => {
    dispatch(getBookingDetailAction())
  })
}

export const favoriteBookingDetail = value => (dispatch, getState) => {
  const state = getState()
  return BookingAPI.favoriteBookingDetail(state.booking.id, value, () => {
    dispatch(getBookingDetailAction())
  })
}

export const requestRecovery = (paramBooking, callback) => (dispatch, getState) => {
  const state = getState()
  const booking = paramBooking || state.booking
  BookingAPI.requestRecoveryBooking(booking.id, { id: booking.id }, (res) => {
    dispatch(getBookingDetailAction())
    callback(res)
  })
}

export const agreeDevina = () => (dispatch, getState) => {
  const state = getState()
  const booking = state.booking
  BookingAPI.devinaBooking(booking.id, { id: booking.id }, () => {
    dispatch(getBookingDetailAction())
  })
}

export const updateCancelReasonIDs = (ids, bk) => (dispatch, getState) => {
  const state = getState()
  const booking = bk || state.booking
  const params = {
    id: booking.id,
    cancelation_reason_ids: [ids]
  }
  return BookingAPI.updateCancelReason(booking.id, params, () => { })
}

export const share = callback => (dispatch, getState) => {
  if (!_.isEmpty(getState().booking.tracking_url)) {
    return callback()
  }
  return BookingAPI.share(getState(), (response) => {
    Promise.resolve(
      dispatch(bookingActionsCreator.bookingUpdate({ booking: response.data.object }))
    ).then(() => {
      callback()
    })
  })
}

export const updateSubAccountCheckBox = value => checkSubAccountActionsCreator.updateSubAccountCheckBox({ value: value })

export const addSubAccount = (subAccountTagPicked = {}) => subAccountTagPickedActionsCreator.subAccountTagPicked(subAccountTagPicked)

const createBooking = (state, dispatch, discountInvalid, callback) => (bookingAttachmentIds) => {
  // eslint-disable-next-line consistent-return
  BookingAPI.create(state, discountInvalid, bookingAttachmentIds, (res) => {
    if (!res) { // Payload Too Large
      dispatch(checkSubAccountActionsCreator.updateSubAccountCheckBox({ value: false }))
      dispatch(addSubAccount())
    }
    dispatch(callback(res))

    return null
  })
}

const uploadBookingAttachments = (state, callback) => {
  const attachments = _.filter(state.attachments, attachment => attachment.file !== undefined)
  const accessToken = state.currentCustomer.authentication_token
  // call api to upload attachment to s3
  const bookingAttachmentIds = _.map(_.filter(state.attachments, attachment => attachment.id), 'id')
  if (!_.isEmpty(attachments)) {
    let attachmentProcessed = 0
    // eslint-disable-next-line array-callback-return
    attachments.forEach((attachment) => {
      AwsImageUpload.getPutUrl(
        accessToken,
        {
          attachment: {
            id: attachment.id,
            document_content_type: attachment.document_content_type,
            document_file_name: attachment.document_file_name,
            document_file_size: attachment.document_file_size,
            document_type: attachment.document_type,
          }
        },
        attachment.file,
        (data, error) => {
          if (!error) {
            bookingAttachmentIds.push(data.photoID)
            attachmentProcessed += 1
            if (attachmentProcessed === attachments.length) {
              callback(bookingAttachmentIds)
            }
          }
        }
      )
    })
  } else {
    callback(bookingAttachmentIds)
  }
}

// for step 3 new booking only
export const create = callback => async (dispatch, getState) => {
  try {
    const state = getState()
    const {
      discountCode = {}
    } = state
    const checkDiscount = await dispatch(checkDiscountCode(discountCode.value))
    const {
      discount_invalid: discountInvalid
    } = checkDiscount
    if (discountCode?.value && discountInvalid) dispatch(showPopupWhenDiscountInvalid(checkDiscount))
    else {
      uploadBookingAttachments(
        state,
        createBooking(state, dispatch, discountInvalid, callback)
      )
    }
  } catch (error) {
    throw new Error(error)
  }
}

// for step 2 -> step 3 on new booking only
export const calculate = callback => (dispatch, getState) => {
  BookingAPI.calculate(getState(), (response) => {
    if (response?.error) {
      toastr.error(response.error)
      $('#loading-modal').removeClass('visible')
      return null
    }
    const state = getState()
    const { booking: oldBooking } = state || {}
    const allBadges = state.extraServices.companyBadges.concat(state.extraServices.vehicleTypeBadges)
    const booking = {
      ...response.object,
      newPayment: response?.payment || {}
    }
    const { total_fees: totalFees } = booking || {}
    booking.booking_extra_requirements = _.filter(state.extraServices.extraRequirements, { selected: true })
    booking.booking_extra_requirements_negative_position = _.filter(
      state.extraServices.extraRequirementsNegativePosition,
      { selected: true }
    )
    booking.booking_badges = _.filter(allBadges, { selected: true })
    booking.vehicle_type = currentVehicleType(state)
    booking.quote_id = response.quote_id
    booking.has_update_price = response.has_update_price || false
    const {
      currentCustomer: {
        authentication_token: authToken,
        current_company_id: companyID,
      },

    } = state
    if (_.isUndefined(oldBooking.use_credit) && authToken) {
      if (!companyID) {
        booking.use_credit = !!booking.credit_amount && booking.use_credit
      }
    } else if (!!booking.credit_amount && !totalFees && !companyID) {
      const firstLocation = state.locations[0]
      dispatch(updateLocation(firstLocation.id, { is_payer: true }))
    }

    Promise.resolve(

      dispatch(bookingActionsCreator.bookingUpdate({
        booking
      }))
    ).then(() => {
      if (typeof callback === 'function') {
        callback(booking)
      }
    })
  })
}

export const calculateCashback = (callback, isServiceUpdate) => (dispatch, getState) => {
  BookingAPI.calculateCashback(getState(), (response) => {
    if (response === null) {
      dispatch(bookingActionsCreator.bookingUpdateCashBackAmount(response))
      return
    }
    if (response.error) {
      // toastr.error(response.error)
      $('#loading-modal').removeClass('visible')
      return
    }
    Promise.resolve(
      dispatch(updateBookingAttributes({ cashBack: response?.object }))
    ).then(() => {
      if (typeof callback === 'function') {
        callback()
      }
    })
  }, isServiceUpdate)
}

/**
 * for step2 we get data from extra-service and calculate it locally.
 *  we don't want to trigger api call to reduce bandwidth
 * But there is an disadvantage here is we have to update webapp code
 *    whenever we need to change the related business logic
 */
export const calculateExtraServicesPrice = () => (dispatch, getState) => {
  const state = getState()
  const {
    booking,
    timeType,
    bookAgainDetails,
    outOfServiceStatus,
    locations,
  } = state
  const price = TallyUtils.calculateExtraServicesPrice(
    {
      extraServices: state.extraServices,
      booking,
      timeType,
      bookAgainDetails,
      outOfServiceStatus,
      locations,
    }
  )

  return Promise.resolve(
    dispatch(bookingActionsCreator.bookingUpdateTally({
      value: {
        step2Price: price,
      },
    }))
  )
}

export const getTallyData = ({
  specificStep = null,
  isLocationChanged = true,
  isValidLH = false
}) => (dispatch, getState) => {
  const state = getState()
  return new Promise((resolve) => {
    const locations = (state.locations || []).filter(location => (location.lat && location.lng))
    if(!locations.length) {
      return resolve()
    }

    Promise.resolve(BookingAPI.getTallyData({
      state, specificStep, isLocationChanged, isValidLH
    }, (response) => {
      const value = _.defaults(
        _.pick(
          response?.object,
          ['total_distance', 'transit_time', 'worst_transit_time', 'is_out_of_service', 'eta_locations_id', 'routes']
        ),
        {
          worst_transit_time: null,
          is_out_of_service: false,
        }
      )
      // for FULL_DAY, we get transit time from separate api
      if (state.timeType === FULL_DAY && !isValidLH) {
        if (state.booking.transit_time) {
          delete value.transit_time
        }
        delete value.worst_transit_time
      }
      // If we specify step, we are requesting for step-specific price
      // otherwise is for total price
      if (specificStep) {
        value[`step${specificStep}Price`] = response.object?.subtotal
        value[`step${specificStep}OutOfServiceAreaFee`] = response.object?.out_of_service_area_fee || 0
      } else {
        value.subtotal = response.object?.subtotal
      }
      Promise.resolve(
        dispatch(bookingActionsCreator.bookingUpdateTally({
          value,
        }))
      ).then(resolve)
    }))
  })
}

export const resetTallyDataStep2 = () => (dispatch, getState) => {
  // mark new_booking to reset bookingAgainDetail when moving to next step
  dispatch(bookingActionsCreator.bookingUpdateTally({
    value: { step2Price: 0 },
  }))
  dispatch(clearExtraRequirementLocations())
}

// This api to fetch transit time for full-day time type
// for non-full day please use getTallyData
export const getTallyTransitTime = ({ isValidLH = false, callback }) => (dispatch, getState) => {
  if (isValidLH) return callback()
  return BookingAPI.getTallyTransitTime(getState(), (response) => {
    const value = _.defaults(
      _.pick(response, ['transit_time', 'worst_transit_time']),
      {
        transit_time: null,
        worst_transit_time: null,
      }
    )

    Promise.resolve(
      dispatch(bookingActionsCreator.bookingUpdateTally({
        value,
      }))
    ).then(() => callback())
  })
}


export const subAccountTagListToState = (payload = []) => (dispatch) => {
  dispatch(subAccountTagListActionsCreator.subAccountTagList(payload))
}

export const getSubAccountTagList = (params = {}) => (dispatch, getState) => {
  const state = getState()
  const currentCustomer = state.currentCustomer
  let newParams = { ...params }
  if (currentCustomer.current_company_id) {
    newParams = { ...newParams, company_id: currentCustomer.current_company_id }
  }
  SubAccountAPI.getSubAccountTagList(
    currentCustomer.authentication_token,
    newParams,
    (res) => {
      dispatch(subAccountTagListToState(res?.data.data))
    }
  )
}

export const confirmedReimbursementsNormal = (
  booking = {}, locationReimbursements = [], callback = (() => { })
) => (dispatch, getState) => {
  const urlParams = new URLSearchParams(window.location.search)
  const accessToken = urlParams.get('access_token')
  const dataSend = {
    id: booking.id,
    locations_attributes: locationReimbursements.map((locationReimbursement) => {
      const tolls = _.get(locationReimbursement, 'tolls[0]')
      const parking = _.get(locationReimbursement, 'parking[0]')
      const waitingTime = _.get(locationReimbursement, 'waiting_time[0]')
      const hasTolls = !_.isEmpty(tolls)
      const hasParking = !_.isEmpty(parking)
      const hasWaitingTime = !_.isEmpty(waitingTime)

      const custom = _.get(locationReimbursement, 'custom', [])
      const result = {
        id: locationReimbursement.id,
        tolls_confirmed: hasTolls ? !!tolls.is_confirmed : true,
        parking_confirmed: hasParking ? !!parking.is_confirmed : true,
        waiting_time_confirmed: hasWaitingTime ? !!waitingTime.is_confirmed : true,
        reimbursements_attributes: custom.map(reimbursement => ({
          id: reimbursement.id,
          is_confirmed: reimbursement.is_confirmed
        }))
      }
      return result
    })
  }
  LocationAPI.confirmLocation(accessToken, booking.id, dataSend, (res) => {
    callback(res)
  })
}

export const confirmedReimbursementsFullDay = (
  booking = {}, locationReimbursements = [], callback = (() => { })
) => (dispatch, getState) => {
  const reimbursementsAttributes = []
  locationReimbursements.forEach((locationReimbursement) => {
    const tolls = _.get(locationReimbursement, 'tolls', [])
    const parking = _.get(locationReimbursement, 'parking', [])
    const waitingTime = _.get(locationReimbursement, 'waiting_time', [])
    const custom = _.get(locationReimbursement, 'custom', [])

    const listReimbursement = tolls.concat(parking, waitingTime, custom)
    listReimbursement.forEach((reimbursement) => {
      reimbursementsAttributes.push({
        id: reimbursement.id,
        is_confirmed: reimbursement.is_confirmed
      })
    })
  })
  const dataSend = {
    id: booking.id,
    reimbursements_attributes: reimbursementsAttributes
  }
  BookingAPI.confirmReimbursement(booking.id, dataSend, (res) => {
    callback(res)
  })
}


export const confirmedReimbursements = (booking, reimbursementInfo, callback = (() => { })) => (dispatch) => {
  const locationReimbursements = reimbursementInfo.location_reimbursements
  if (booking.time_type === FULL_DAY || booking.status === FULL_DAY) {
    dispatch(confirmedReimbursementsFullDay(booking, locationReimbursements, callback))
  } else {
    dispatch(confirmedReimbursementsNormal(booking, locationReimbursements, callback))
  }
}

export const cancelToEdit = (options = {}) => (dispatch, getState) => {
  const state = getState()
  const booking = state.booking
  const extraInfos = state.extraInfos
  const isNewEntry = extraInfos?.enable_new_booking_entry_service
  BookingAPI.cancelToEditBooking(booking.id, isNewEntry ? { booking_id: booking.id } : { id: booking.id }, (response) => {
    if (response) {
      const bookingId = isNewEntry ? response.data.booking_id : response.data.object.id
      if ( bookingId )  {
        let params = ''
        if (options) {
          if (options.is_new_driver) {
            params = '?is_new_driver=true'
          } else if (options.driver_id) {
            params = `?driver_id=${options.driver_id}`
          }
        }  
        window.location = `/bookings/${bookingId}/edit${params}`
      } else {
        toastr.error(response.data?.message)
      }
    } else {
      toastr.error(response.data.error)
    }
  }, isNewEntry)
}

const generateEstimationTransitTimeAttributes = ({
  booking, pickupTime, timeType, quickChoiceID,
}) => {
  const estimateTransitTimesAttributes = []
  /* eslint-disable no-param-reassign */
  // for 'quick' time type (timeType = 'now' and no quickChoice)
  if (timeType === NOW && !quickChoiceID) {
    pickupTime = moment().format()
  }

  if (pickupTime && booking.transit_time) {
    const estimateTransitTimes = {
      eta: moment(pickupTime).add(booking.transit_time, 's').format()
    }
    if (booking.worst_transit_time) {
      estimateTransitTimes.worst_case_eta = moment(pickupTime).add(booking.worst_transit_time, 's').format()
    }

    estimateTransitTimesAttributes.push(estimateTransitTimes)
  }

  return estimateTransitTimesAttributes
}

export const submitEditExtraPerLocationsParams = (location, isCeb) => {
  const extPerLocations = _.filter(location.extra_requirement_locations, e => e.selected_amount > 0)
  return extPerLocations.map((extPerLocation) => {
    const result = {
      extra_requirement_id: extPerLocation.extra_requirement_id,
      selected_amount: extPerLocation.selected_amount,
      unit_price: extPerLocation.unit_price
    }
    if (isCeb && extPerLocation.id) {
      result.id = extPerLocation.id
    }
    return result
  })
}

const submitEditBooking = (callback) => async (bookingAttachmentIds) => {
  const state = store.getState()
  const dispatch = store.dispatch
  const totalExtraRequirements = state.extraServices.extraRequirements.concat(
    state.extraServices.extraRequirementsNegativePosition
  )
  const locations = _.filter(state.locations, (location) => location.lat !== undefined)
  const relatedAttachmentIds = _.compact(
    _.map(
      _.filter(state.attachments, (attachment) => attachment.attachable_type !== 'Booking'),
      'id'
    )
  )
  const allBadges = state.extraServices.companyBadges.concat(state.extraServices.vehicleTypeBadges)
  const validDiscountCode =
    state.discountCode && state.discountCode.status === DISCOUNT_CODE_STATUS.valid ? state.discountCode.value : ''

  const companySettings = state.extraServices.companySettings
  const customReimbursements = state.extraServices.customReimbursements
  const booking = state.booking
  const extraInfos = state.extraInfos
  const isCeb = isCustomerEditBooking()
  const bookAgainDetails = state.bookAgainDetails
  const bookAgainLocations = bookAgainDetails.locations
  const bookAgainExtras = bookAgainDetails.booking_extra_requirements.concat(
    bookAgainDetails.booking_extra_requirements_negative_position
  )
  const estimateTransitTimesAttributes = generateEstimationTransitTimeAttributes({
    booking,
    timeType: state.timeType,
    pickupTime: state.pickupTime,
    quickChoiceID: state.quickChoiceID,
  })
  const hasEstimateTransitTime = estimateTransitTimesAttributes.length > 0
  const params = {
    id: state.bookAgainDetails.id,
    time_type: state.timeType,
    display_time_type: state.timeTypeUI,
    vehicle_type_id: state.selectedVehicleTypeID,
    service_type_id: state.selectedServiceTypeID,
    full_day_selected_amount: _.toInteger(state.extraServices.fullDayPricing.selected_amount),
    company_id: state.currentCustomer.current_company_id || 0,
    pickup_time: state.pickupTime,
    quick_choice_id: state.quickChoiceID,
    display_quick_choice_id: state.quickChoiceID,
    discount_code: validDiscountCode || '',
    note: state.note,
    marked_as_favorite: state.others.saveToFavorites || false,
    locations_attributes: locations.map((location, index) => {
      const extraPerLocations = submitEditExtraPerLocationsParams(location, isCeb)
      const bookAgainLocation = bookAgainLocations[index]
      const isPhoneMask = !_.isEmpty(location.phone_mask)
      const result = {
        latitude: location.lat,
        longitude: location.lng,
        name: location.name,
        extra_requirement_locations_attributes: extraPerLocations,
        description: location.description || '',
        is_payer: location.is_payer || false,
        recipient_name: location.recipient_name,
        recipient_phone: location.recipient_phone,
        need_cod: location.need_cod || false,
        need_pod: location.need_pod || false,
        cod_note: CPODUtils.getPramsCODPODNote(location, 'cod_note', booking),
        pod_note: CPODUtils.getPramsCODPODNote(location, 'pod_note', booking),
        cod_invoice_fees: location.cod_invoice_fees,
        is_phone_mask: isPhoneMask,
      }
      if (bookAgainLocation?.id) {
        result.id = bookAgainLocation.id
      }
      return result
    }),
    booking_extra_requirements_attributes: _.filter(totalExtraRequirements, (extraRequirement) => {
      if (state.timeType === LONG_HAUL) {
        return extraRequirement.selected === true && extraRequirement.is_flat_per_location === false
      }
      return extraRequirement.selected === true
    }).map((extraRequirement) => {
      const result = {
        extra_requirement_id: extraRequirement.id,
        selected_amount: extraRequirement.selected_amount,
        is_flat: extraRequirement.is_flat,
        position: extraRequirement.position,
        unit_price: extraRequirement.unit_price,
        level_price: extraRequirement.level_price || '',
        extra_requirement_pricing_id: extraRequirement.extra_requirement_pricing_id || '',
      }
      if (!_.isUndefined(extraRequirement.selectedPricing)) {
        result.unit_price = extraRequirement.selectedPricing.fees
        result.level_price = extraRequirement.selectedPricing.level_price
        result.extra_requirement_pricing_id = extraRequirement.selectedPricing.id
      }
      const bookAgainExtra = _.find(bookAgainExtras, (ext) => ext.extra_requirement_id === extraRequirement.id)
      if (isCeb && !_.isUndefined(bookAgainExtra)) {
        result.id = bookAgainExtra.id
      }
      return result
    }),
    booking_tracking_attributes: CPODUtils.validateParamsDocumentReturn(locations, state.checkLocations)
      ? state.documentReturn
      : null,
    booking_badges_attributes: _.filter(allBadges, { selected: true }).map((badge) => ({
      badgeable_relation_id: badge.id,
      badgeable_relation_type: badge.badgeable_relation_type,
      selected_amount: badge.selected_amount,
    })),
    booking_reimbursements_attributes:
      state.timeType !== LONG_HAUL && !extraInfos.enable_parking_tolls_feature
        ? null
        : convertCustomReimbursementToParam(customReimbursements),
    booking_attachment_ids: bookingAttachmentIds,
    related_attachment_ids: relatedAttachmentIds,
    just_signed_in: false,
    job_order_number: state.jobOrderNumber,
    allow_parking_fees: companySettings.allow_parking_fees,
    allow_tolls_fees: companySettings.allow_tolls_fees,
    allow_waiting_time_fees: companySettings.allow_waiting_time_fees,
    send_first_to_favorite: booking.driverPreferencesTab !== DRIVER_PREFERENCES.all,
    round_trip_discount: state.roundTripDiscount,
    assign_driver_booking_attributes: {
      driver_id: state.assignedDriver ? state.assignedDriver.id : null,
      fleet_partner_id: state.assignedDriver ? state.assignedDriver.fleet_partner_id : null,
    },
    is_new_driver: state.isNewDriver,
    is_ceb: isCeb,
    has_settlement: !_.isEmpty(bookAgainDetails.settlement_details),
    require_signatures: state.requireSignatures,
    ...(hasEstimateTransitTime ? { estimate_transit_times_attributes: estimateTransitTimesAttributes } : {}),
    ...(booking.eta_locations_id ? { eta_locations_id: booking.eta_locations_id } : {}),

    ...(!_.isEmpty(bookAgainDetails.sub_account_tag)
      ? {
          sub_account_tag_attributes: {
            sub_account_id: bookAgainDetails.sub_account_tag.sub_account_id,
            sub_account_name: bookAgainDetails.sub_account_tag.sub_account_name,
          },
        }
      : {}),
    checkSubAccount: state.checkSubAccount,
  }
  if (booking.quote_id) {
    _.assign(params, {
      quote_id: booking && booking.quote_id,
      has_update_price: booking && booking.has_update_price,
    })
  }

  params.use_credit = booking.use_credit
  const bankTransfer = booking?.bankTransfer
  const paymentMethodFor_nonBp = booking.payment_method_for_non_bp
  const isEditWithPayment = Utils.isAddPaymentAttr(bankTransfer, paymentMethodFor_nonBp)
  if (isEditWithPayment) {
    params.payment_attributes = Utils.getPaymentAttributes(bankTransfer)
    params['include[]'] = 'payment'
  }

  if (booking?.newPayment?.id || isEditWithPayment) params['include[]'] = 'payment'
  const isNewEntry = extraInfos?.enable_new_booking_entry_service
  const response = await BookingAPI.updateBooking(state.bookAgainDetails.id, params, isNewEntry)
  if (response.status === 422 && bookAgainDetails && bookAgainDetails.sub_account_tag) {
    const newBookAgainDetails = { ...bookAgainDetails }
    delete newBookAgainDetails.sub_account_tag
    dispatch(updateBookAgainDetails(newBookAgainDetails))
  }
  dispatch(callback(response))
}

export const editBooking = callback => async (dispatch, getState) => {
  try {
    const state = getState()
    const {
      discountCode = {}
    } = state
    const checkDiscount = await dispatch(checkDiscountCode(discountCode.value))
    const {
      discount_invalid: discountInvalid
    } = checkDiscount
    if (discountCode?.value && discountInvalid) dispatch(showPopupWhenDiscountInvalid(checkDiscount))
    else uploadBookingAttachments(state, submitEditBooking(callback))
  } catch (error) {
    throw new Error(error)
  }
}
export const updateCancelReasonForBatch = (reasonID, bookingID, user) => () => {
  const params = {
    id: bookingID,
    cancelation_reason_ids: reasonID
  }
  BookingAPI.cancelBooking(bookingID, params, () => { })
}

export const searchDriverByReferralCode = (data, stepActions, callback) => () => {
  const referralCode = _.toLower(_.trim(data.driverReferralCode))
  if (_.isEmpty(referralCode) || !data.time_type) {
    return
  }
  showLoading()
  const driver = _.find(data.drivers, ['referral_code', referralCode])
  if (!_.isUndefined(driver)) {
    callback([driver])
    hideLoading()
    return
  }
  const params = {
    referral_code: referralCode,
    include_fleet_driver: _.isUndefined(data.include_fleet_driver) ? true : data.include_fleet_driver,
    'include': ['driver.vehicles', 'driver.current_vehicle'],
    'only': ['id', 'referral_code', 'name', 'driver_image_url', 'driver_type', 'badges', 'fleet_partner_id', 'fleet_partner_name', 'current_vehicle', 'is_requirement_met', 'is_favorite_driver'],
    'only[][vehicles]': ['vehicle_attributes', 'vehicle_type_name'],
    vehicle_type_id: data.vehicle_type_id,
    time_type: data.time_type,
    company_id: data.company_id,
    area_id: data.area_id,
    country_code: data.country_code,
    'extra_requirement_ids': data.extra_requirement_ids,
    'extra_requirement_locations_ids': data.extra_requirement_locations_ids,
    'badges': data.badges,
    'reimbursement_ids': data.reimbursement_ids,
    check_requirement_not_met: true,
    check_favorite_driver: true,
    location_need_cod: !!data.location_need_cod,
    location_need_pod: !!data.location_need_pod,
    new_gen_pod: !!data.new_gen_pod,
  }
  CustomerAPI.searchDriverByReferralCode(
    data.authenticationToken,
    params,
    (response) => {
      hideLoading()
      if (response.data.error) {
        toastr.remove()
        toastr.error(response.data.error)
      } else {
        callback(response.data.data)
      }
    }
  )
}

export const searchDriverByReferralCodeNew = async (data) => {
  const referralCode = _.toLower(_.trim(data.driverReferralCode))
  if (_.isEmpty(referralCode)|| !data.time_type) {
    return
  }
  showLoading()
  const driver = _.find(data.drivers, ['referral_code', referralCode])
  if (!_.isUndefined(driver)) {
    hideLoading()
    return [driver]
  }
  const params = {
    referral_code: referralCode,
    include_fleet_driver: _.isUndefined(data.include_fleet_driver) ? true : data.include_fleet_driver,
    include: ['driver.vehicles', 'driver.current_vehicle'],
    only: [
      'id',
      'referral_code',
      'name',
      'driver_image_url',
      'driver_type',
      'badges',
      'fleet_partner_id',
      'fleet_partner_name',
      'current_vehicle',
      'is_requirement_met',
      'is_favorite_driver',
    ],
    'only[][vehicles]': ['vehicle_attributes', 'vehicle_type_name'],
    vehicle_type_id: data.vehicle_type_id,
    time_type: data.time_type,
    company_id: data.company_id,
    area_id: data.area_id,
    country_code: data.country_code,
    extra_requirement_ids: data.extra_requirement_ids || [],
    extra_requirement_locations_ids: data.extra_requirement_locations_ids || [],
    badges: data.badges || [],
    reimbursement_ids: data.reimbursement_ids || [],
    check_requirement_not_met: true,
    check_favorite_driver: true,
    location_need_cod: !!data.location_need_cod,
    location_need_pod: !!data.location_need_pod,
    new_gen_pod: !!data.new_gen_pod,
  }
  const { data: res } = await CustomerAPI.searchDriverByReferralCodeNew(params)
  hideLoading()
  if (res.error) {
    toastr.remove()
    toastr.error(data.error)
  } else {
    return res
  }
}

export const checkSearchDriverToAssign = (data, stepActions, callback) => () => {
  showLoading()
  const params = {
    area_id: data.areaID,
    country_code: data.countryCode,
    vehicle_type_id: data.vehicleTypeId,
    time_type: data.timeType,
    company_id: data.companyID,
    extra_requirement_ids: data.extraRequirementIDs,
    extra_requirement_locations_ids: data.extraRequirementLocationsIDs,
    badges: data.badgeIDs,
    reimbursement_ids: data.reimbursementIDs,
    location_need_cod: !!data.location_need_cod,
    location_need_pod: !!data.location_need_pod,
    new_gen_pod: !!data.new_gen_pod,
  }
  CustomerAPI.validateSearchDriverToAssign(
    data.authenticationToken,
    data.driver.id,
    params,
    (response) => {
      hideLoading()
      if (response.data.error) {
        if (response.data.statusCode === 405) {
          $(data.elementInvalidDriverPopup).addClass('visible')
        } else {
          toastr.remove()
          toastr.error(response.data.error)
        }
      } else {
        callback(response.data)
      }
    }
  )
}

export const checkCancelable = (bookingID, callback) => (dispatch, getState) => {
  BookingAPI.checkCancleAble(bookingID, (response) => {
    callback(response?.data)
  })
}

export const updateBookingAttributes = attrs => (dispatch, getState) => {
  let booking = getState().booking
  booking = _.assign({}, booking, attrs)
  dispatch(bookingActionsCreator.bookingUpdate({
    booking
  }))
}

export const cancelFine = (cancelLogId, status, callback) => (dispatch, getState) => {
  const dataSend = {
    id: cancelLogId,
    status
  }
  BookingAPI.cancleFinePayment(cancelLogId, dataSend, () => {
    Promise.resolve(
      dispatch(updateBookingAttributes(
        { customer_cancel_log: { fine_payment_status: status, id: cancelLogId } }
      ))
    ).then(() => {
      callback()
    })
  })
}


export const clearDataChangeForEditBooking = () => (dispatch) => {
  dispatch(dataChangesActionsCreator.updateDataChange(false))
}

// turn booking again detail into state
export const turnBookingAgainDataIntoState = bookAgainDetails => (dispatch) => {
  // for single booking most of actions directly rely on store (state)
  const {
    discount_code_info: discountCode,
  } = bookAgainDetails

  const tasks = []

  if (discountCode) {
    tasks.push(
      dispatch(discountCodeActionsCreator.updateDiscountCode({ value: discountCode, status: DISCOUNT_CODE_STATUS.valid }))
    )
  }

  return Promise.all(tasks)
}

export const createBookingForLTL = formData => (dispatch, getState) => {
  const state = getState()
  BookingAPI.createBookingForLTL(formData, state, (response) => {
    const id = response.data.id
    const { extraInfos } = state
    const defaultCountry = extraInfos.country_code.toLowerCase()
    const currentCompanyId = get(state, 'currentCustomer.current_company_id')
    const idUser = get(state, 'currentCustomer.id')
    const companyId = currentCompanyId || idUser
    Promise.resolve(
    ).then(() => {
      const param = Utils.buildParamToFTL({
        countryCode: defaultCountry,
        areaId: extraInfos.area_id,
        companyId,
      })
      window.location.replace(`${Utils.getLTLCustomerDomain(defaultCountry)}/booking/${id}?${param}`)
    }).catch(() => {
      // window.location.replace('http://customer-cargo.dev.deliveree.com/')
    })
  })
}

export const updateBookingForLTL = (formData, shipmentId) => (dispatch, getState) => {
  const state = getState()
  const { extraInfos } = state
  const defaultCountry = extraInfos.country_code.toLowerCase()
  BookingAPI.updateBookingForLTL(formData, state, () => {
    Promise.resolve(
    ).then(() => {
      const currentCompanyId = get(state, 'currentCustomer.current_company_id')
      const idUser = get(state, 'currentCustomer.id')
      const companyId = currentCompanyId || idUser
      const param = Utils.buildParamToFTL({
        countryCode: defaultCountry,
        areaId: extraInfos.area_id,
        companyId,
      })
      window.location.replace(`
            ${Utils.getLTLCustomerDomain(defaultCountry)}/booking/${shipmentId}?${param}
          `)
    }).catch(() => {
      // console.log('error ==>', error)
    })
  })
}

export const getShipmentLTLDetail = (shipmentId, callback) => (dispatch, getState) => {
  const state = getState()
  const { extraInfos } = state
  BookingAPI.getShipmentLTLDetail(shipmentId, extraInfos.country_code, (response) => {
    Promise.resolve(
      dispatch(bookingActionsCreator.getShipmentLTLDataSuccess({
        value: response.data
      }))
    ).then(() => {
      callback(response.data)
    }).catch(() => {
    })
  })
}

export const updateOutOfServicePartialLoad = value => (dispatch) => {
  dispatch(bookingActionsCreator.updateStatusOutServicePartialLoad({
    value
  }))
}

export const updateReqNotMetReason = (payload, bookingID) => dispatch => {
  if (bookingID) {
    dispatch(bookingsActionsCreator.updateRequirementNotMetReason({
      payload,
      bookingID
    }))
  } else {
    dispatch(bookingActionsCreator.updateRequirementNotMetReason(payload))
  }
}

export const getRequirementNotMet = (data, stepActions) => (dispatch) => {
  stepActions.loading()
  const params = {
    area_id: data.area_id,
    country_code: data.country_code,
    vehicle_type_id: data.vehicle_type_id,
    time_type: data.time_type,
    company_id: data.company_id,
    extra_requirement_ids: data.extra_requirement_ids,
    extra_requirement_locations_ids: data.extra_requirement_locations_ids,
    badges: data.badges,
    reimbursement_ids: data.reimbursement_ids,
    location_need_cod: !!data.location_need_cod,
    location_need_pod: !!data.location_need_pod,
    new_gen_pod: !!data.new_gen_pod,
  }

  CustomerAPI.getRequirementNotMet(
    data.driverID,
    params,
    data.authenticationToken,
    (response) => {
      stepActions.loaded()
      if (response.data.error) {
        toastr.remove()
        toastr.error(response.data.error)
      } else {
        dispatch(updateReqNotMetReason(response.data, data.bookingID))
      }
    }
  )
}

export const loadFavoriteDrivers = (params, stepActions, callback, type = typeBooking.single) => (dispatch) => {
  if (!params.time_type) return hideLoading()
  const apiParams = {
    include_fleet_driver: _.isUndefined(params.include_fleet_driver) ? true : params.include_fleet_driver,
    'include': ['driver.vehicles', 'driver.current_vehicle'],
    'only': ['id', 'referral_code', 'name', 'driver_image_url', 'driver_type', 'fleet_partner_id', 'fleet_partner_name', 'is_requirement_met', 'is_favorite_driver'],
    'only[][vehicles]': ['vehicle_attributes', 'vehicle_type_name'],
    vehicle_type_id: params.vehicle_type_id,
    time_type: params.time_type,
    area_id: params.area_id,
    country_code: params.country_code,
    'extra_requirement_ids': params.extra_requirement_ids,
    'extra_requirement_locations_ids': params.extra_requirement_locations_ids,
    'badges': params.badges,
    'reimbursement_ids': params.reimbursement_ids,
    check_requirement_not_met: true,
    check_favorite_driver: true,
    include_recently_assigned_driver: true,
    item_type: 'favorite',
    page: params.page,
    location_need_cod: !!params.location_need_cod,
    location_need_pod: !!params.location_need_pod,
    new_gen_pod: !!params.new_gen_pod,
  }

  if (params.company_id) {
    apiParams.company_id = params.company_id
  }

  CustomerAPI.getFavoriteOrBannedDrivers(
    params.authentication_token,
    apiParams,
    (response) => {
      if (response.data.error) {
        toastr.remove()
        toastr.error(response.data.error)
        stepActions.loaded()
      } else {
        let action = {
          drivers: response.data.data || [],
          recentDriverID: response.data.recently_assigned_driver_id || '',
          pagination: response.data.pagination || {},
          loadingMore: params.loadingMore
        }

        if (params.bookingID) {
          action = {
            ...action,
            bookingID: params.bookingID,
          }
        }

        Promise.resolve(
          dispatch(type === typeBooking.multiple ? bookingsActionsCreator.receiveFavoriteDrivers(action) : bookingActionsCreator.receiveFavoriteDrivers(action)),
          callback(response)
        ).then(() => {
          stepActions.loaded()
        })
      }
    }
  )
}

export const addToFavoriteDrivers = assignedDriver => (dispatch, getState) => {
  const { currentCustomer } = getState()
  const companyID = currentCustomer.current_company_id === 0 ? undefined : currentCustomer.current_company_id
  const authenticationToken = currentCustomer.authentication_token
  let params = {
    'customer_drivers_attributes': [
      {
        item_type: DRIVER_PREFERENCES.favorite,
        driver_id: assignedDriver.id,
        company_id: companyID
      }
    ]
  }

  if (assignedDriver.fleet_partner_id) {
    params = {
      fleet_partner_id: assignedDriver.fleet_partner_id,
      driver_id: assignedDriver.id,
      company_id: companyID
    }
    CustomerAPI.addFavoriteFleetDriver(authenticationToken, params)
  }

  CustomerAPI.addFavoriteDriver(authenticationToken, params)
}

export const signInProgressFinished = isFinished => bookingActionsCreator.signInProgressFinished(isFinished)

export const getFavoriteAmount = (params, cb) => async (dispatch) => {
  const apiParams = {
    include_fleet_driver: _.isUndefined(params.include_fleet_driver) ? true : params.include_fleet_driver,
    vehicle_type_id: params.vehicle_type_id,
    area_id: params.area_id,
    country_code: params.country_code,
    item_type: 'favorite',
  }

  if (params.company_id) {
    apiParams.company_id = params.company_id
  }

  const result = await CustomerAPI.getFavoriteAmount(params.authentication_token, apiParams)
  const amount = result?.data?.error ? 0 : result.data
  if (params.bookingID) {
    dispatch(bookingsActionsCreator.updateBooking({ id: params.bookingID, attrs: { favoriteAmount: amount } }))
  } else {
    dispatch(othersActionsCreator.updateOthers({ attrs : { favoriteDriversAmount: amount } }))
  }

  if (cb) {
    cb()
  }

}

export const getBooking = (bookingId, query) => (dispatch, getState) => {
  const { currentCustomer } = getState()
  BookingAPI.get(bookingId, currentCustomer, (response) => {
    const booking = response?.object
    dispatch(bookingActionsCreator.getBookingSuccess(booking))
  }, query)
}
export const editLocation = (locationId, body) => dispatch => new Promise((resolve, reject) => {
  BookingAPI.editNote(locationId, body, (response) => {
    if (response.error) {
      reject(response.error)
      return
    }
    dispatch(bookingActionsCreator.updateLocationSuccess({
      locationId,
      payload: body.description
    }))
    resolve(response)
  })
})
export const retryDevinaLocating = ({ bookingId, batchToken, callback }) => async (dispatch) => {
  const authToken = window.localStorage.getItem('access_token') || ''
  const params = {
    bookingId,
    authToken,
  }
  try {
    showLoading()
    const response = await BookingAPI.retryDevinaLocating(params)
    if (response.data.error) {
      toastr.error(response.data.error)
      return
    }
    if (typeof callback === 'function') {
      callback()
    }
    if (batchToken) {
      dispatch(updateBatchBookingAttrs({
        batch_tracking_token: batchToken,
        status: STATUS_CS_FINDING_DRIVER
      }))
      return
    }
    dispatch(getBookingDetailAction(() => {
      dispatch(updateCurrentPopupID(''))
      hideLoading()
    }))
  } catch (error) {
    toastr.error(error.message)
  } finally{
    hideLoading()
  }
}
export const editCustomerDriverReference = payload => (dispatch, getState) => new Promise((resolve, reject) => {
  const { currentCustomer } = getState()
  const { customerDriver, itemType, _destroy } = payload
  const { driver, fleet_partner: fleetPartner } = customerDriver
  const accessToken = currentCustomer.authentication_token
  const companyId = currentCustomer.current_company_id || undefined
  function handleResult(response) {
    closeLoading()
    if (response.error) {
      showError()
      reject()
    } else {
      dispatch(customerDriverActionsCreator.customerDriverReferenceSuccess(payload))
      resolve(response)
    }
  }
  openLoading()
  const params = {
    item_type: itemType,
    driver_id: driver.id,
    fleet_partner_id: fleetPartner?.id,
  }
  if (_destroy && itemType === DRIVER_PREFERENCES.favorite && fleetPartner?.id) {
    params.destroy_all = _destroy
  }
  CustomerAPI.updatePreferenceListDrivers(accessToken, {
    'customer_drivers_attributes': [params],
    item_type: itemType,
    company_id: companyId,
    _destroy,
  }, handleResult)
})

export const getBookingInfo = (params = {}) => async (dispatch, getState) => {
  const { loading } = params
  const { booking, currentCustomer } = getState()
  const includeParams = ['driver.vehicles', 'locations', 'service_type', 'extra_requirements', 'badges', 'vehicle_type', 'reimbursements', 'fleet_partner', 'locations.reimbursements', 'custom_reimbursements', 'sub_account_tag', 'batch_id', 'exceed_allowance_time']
  const query = {
    'include': includeParams,
  }
  const bookingId = params.bookingId || booking.id
  if (loading) showLoading()
  const response = await BookingAPI.getBookingInfo(currentCustomer.authentication_token, bookingId, query)
  if (response.data.error) {
    toastr.error(response.data.error)
  } else {
    const bookingInfo = response.data.object
    const {
      status,
      canceled_status_was: canceledStatusWas,
      recovery_status: recoveryStatus,
      canceled_by_driver: canceledByDriver,
      customer_cancel_log: customerCancelLog,
    } = bookingInfo
    dispatch(bookingActionsCreator.bookingUpdate({
      booking: bookingInfo
    }))
    switch (status) {
      case STATUS_ASSIGNING_DRIVER_TIMEOUT:
      case STATUS_DRIVER_DECLINED_BOOKING:
        dispatch(updateCurrentPopupID(POPUP_DEVINA))
        break
      case STATUS_LOCATING_DRIVER_TIMEOUT: {
        if (booking.batch_id) {
          // eslint-disable-next-line no-use-before-define
          await dispatch(getDevinaInfo({
            section_type: SECTION_TYPE.CUSTOMER_DEVINA_STAGE_INFO,
          }))
        }
        break
      }
      case STATUS_CANCELLED: {
        const isCustomerCancel = window.localStorage.getItem('is_customer_cancel')
        if (isCustomerCancel) {
          window.localStorage.removeItem('is_customer_cancel')
          break
        }
        if (recoveryStatus === RECOVERY_STATUS_DECLINE_RECOVERY
          || canceledStatusWas === STATUS_LOCATING_DRIVER) {
          window.location.href = '/bookings?search[scope]=canceled'
          break
        }
        if (canceledByDriver) {
          dispatch(updateCurrentPopupID(POPUP_DEVINA))
          break
        }
        if (canceledStatusWas === STATUS_CS_FINDING_DRIVER) {
          // eslint-disable-next-line no-use-before-define
          await dispatch(getDevinaInfo({
            section_type: SECTION_TYPE.CUSTOMER_DEVINA_STAGE_INFO
          }))
        }
        if (customerCancelLog?.fine_payment_status === PAYMENT_PENDING) {
          dispatch(updateCurrentPopupID(POPUP_PENALTY_FEE))
        }
        break
      }
      default:
        break
    }
  }
  if (loading) hideLoading()
}
export const editBookingAction = (bookingId, body) => async (dispatch) => {
  try {
    showLoading()
    const response = await BookingAPI.editBooking(bookingId, body)
    if (response.data.error) {
      throw new Error(response.data.error)
    }
    dispatch(getBookingDetailAction(() => {
      dispatch(updateCurrentPopupID(''))
      hideLoading()
    }))
  } catch (error) {
    toastr.error(error.message)
    hideLoading()
  }
}
export const updateAssignDriverBooking = payload => devinaActionsCreator.updateAssignDriverBooking(payload)
export const updateDevinaStage = payload => devinaActionsCreator.updateDevinaStage(payload)
export const updateBookingAction = payload => bookingActionsCreator.bookingUpdate({
  booking: payload,
})
export const updateVehicleTypeBooking = payload => devinaActionsCreator.updateVehicleType(payload)
export const updateMaskLoading = payload => devinaActionsCreator.updateMaskLoading(payload)
export const updateLocatingDuration = payload => devinaActionsCreator.updateLocatingDuration(payload)
export const updateCSfindingTimeOutAt = payload => devinaActionsCreator.updateCsFindingTimeout(payload)

export const getDevinaInfo = params => async (dispatch, getState) => {
  const { booking } = getState()
  const { section_type: sectionType, callback } = params
  const bookingId = params.bookingId || booking.id
  const query = {
    section_type: sectionType
  }
  dispatch(updateMaskLoading(true))
  const response = await CustomerAPI.getCustomerBooking(bookingId, query)
  if (response) {
    if (sectionType === SECTION_TYPE.CUSTOMER_ASSIGNED_DRIVER) {
      const { assign_driver_booking: assignDriverBooking } = response
      dispatch(updateAssignDriverBooking(assignDriverBooking))
    } else if (sectionType === SECTION_TYPE.CUSTOMER_DEVINA_STAGE_INFO) {
      const devinaStage = response.devina_stage || {}
      dispatch(updateDevinaStage(devinaStage))

      // case: multi stages OFF
      const { popup } = devinaStage
      if (!popup) {
        dispatch(updateCurrentPopupID(POPUP_DEVINA))
        return
      }
      // case: multi stages ON
      if (popup.popup_type !== DEVINA_POPUP_TYPE.NOT_SHOW) {
        dispatch(updateCurrentPopupID(POPUP_DEVINA))
      }
    } else if (sectionType === SECTION_TYPE.VEHICLE_TYPE_SETTINGS) {
      dispatch(updateVehicleTypeBooking(response.vehicle_type))
    }
    if (sectionType === SECTION_TYPE.MINOR_CHANGE && typeof callback === 'function') {
      callback(response)
    }
  }
  dispatch(updateMaskLoading(false))
}

export const getTippingSetting = (params) => async (dispatch, getState) => {
  const { booking } = getState()
  const { section_type: sectionType, callback } = params
  const bookingId = params.bookingId || booking.id
  const query = {
    section_type: sectionType
  }

  const response = await CustomerAPI.getCustomerBooking(bookingId, query)

  if(response) {
    dispatch(updateVendorTipping(response?.vendor_tipping_setting ? {
      ...response?.vendor_tipping_setting,
      tippingTimes: response?.tipping_times
    } : response?.vendor_tipping_setting))
    dispatch(updateVendorTippingAmount(response?.vendor_tipping_amount))
  }
}

export const updateVendorTipping = payload => bookingActionsCreator.updateVendorTippingSetting(payload)
export const updateVendorTippingAmount = payload => bookingActionsCreator.updateVendorTippingAmount(payload)
export const updateCancelable = payload => devinaActionsCreator.updateCancelable(payload)
export const getCustomerDriverAction = () => customerDriverActionsCreator.getCustomerDriver()
export const getCustomerDriverSuccess = payload => customerDriverActionsCreator.getCustomerDriverSuccess(payload)
export const resetCustomerDriverAction = () => customerDriverActionsCreator.resetCustomerDriver()

let showPopup = null
export const getCustomerDriver = params => async (dispatch, getState) => {
  const { booking } = getState()
  const bookingId = params.bookingId || booking.id
  try {
    dispatch(getCustomerDriverAction())
    const response = await CustomerAPI.getCustomerBooking(bookingId, params)
    dispatch(getCustomerDriverSuccess(response))
    // prevent showing popup for the first loading.
    if (showPopup === null) {
      showPopup = !!(booking.id && !booking.fleet_partner_id && !booking.driver_id)
    } else showPopup = true
    if (showPopup) {
      dispatch(updateCurrentPopupID(POPUP_DEVINA))
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
}

export const updateBookingTrackingPage = () => async (dispatch, getState) => {
  const { booking, currentCustomer } = getState()
  const includeParams = [
    'driver.vehicles',
    'locations',
    'service_type',
    'extra_requirements',
    'badges',
    'vehicle_type',
    'reimbursements',
    'fleet_partner',
    'custom_reimbursements',
    'sub_account_tag',
    'batch_id',
    'exceed_allowance_time',
    ...(booking.tracking_token ? ['tracking_token'] : [])
  ]
  const query = {
    'include': includeParams,
    ...(booking.tracking_token && { tracking_token: booking.tracking_token })
  }
  const response = await BookingAPI.getBookingInfo(currentCustomer.authentication_token, booking.id, query)
  if (response.data.error) {
    toastr.error(response.data.error)
    return
  }
  if (checkIfRedirectToDetail(response.data.object)) {
    window.location.href = `/bookings/${booking.id}`
    return
  }
  dispatch(bookingActionsCreator.bookingUpdate({
    booking: response.data.object
  }))
}

export const getBookingPaymentDetailAction = (
  id, sectionType = SECTION_TYPE_PAYMENT_LIST[CUSTOMER_PAYMENT],
) => async (dispatch) => {
  const params = {
    id,
    section_type: sectionType,
  }
  const bookingPaymentDetails = await CustomerAPI.getCustomerBooking(id, params)
  dispatch(updateBookingAttributes({ bookingPaymentDetails: { ...bookingPaymentDetails?.payment } }))
}

export const getBookingPaymentSettingAction = (id, sectionType, callback = () => undefined) => async (dispatch) => {
  const params = {
    id,
    section_type: sectionType,
  }
  const settingPayment = await CustomerAPI.getCustomerBooking(id, params)
  if (!_.isEmpty(settingPayment?.special_settings)) {
    dispatch(updateBookingAttributes({ special_settings: { ...settingPayment.special_settings } }))
    dispatch(bookingAgainDetailsActionsCreator.updateBookAgainDetails({ special_settings: { ...settingPayment.special_settings } }))
    callback()
  }
}

export const getBookingPaymentDetailStatusAction = (
  id, isGetSettingAfterExpired = false, sectionType = SECTION_TYPE_PAYMENT_LIST[CUSTOMER_PAYMENT_STATUS],
) => async (dispatch, getState) => {
  const params = {
    id,
    section_type: sectionType,
  }
  const bookingPaymentDetailsStatus = await CustomerAPI.getCustomerBooking(id, params)
  dispatch(updateBookingAttributes({ bookingPaymentDetailsStatus }))
  if (isGetSettingAfterExpired) {
    const payment = bookingPaymentDetailsStatus?.payment
    const status = payment?.status
    if (FAILED_PAYMENT_STATUS.includes(status) || BOOKING_FAILED_STATUS.includes(bookingPaymentDetailsStatus.status)) {
      const booking = getState().booking
      const paymentId = payment.payment_method_setting_uuid
      const countryCode = booking.country_code
      dispatch(getItemPaymentSettingsAction(paymentId, countryCode))
    }
  }
}

export const handleInvalidPaymentAction = (countryCode, bankId, handleClose = () => undefined) => async (dispatch) => {
  showLoading()
  const dataAPI = await PaymentIntegrationAPI.getPaymentSettingAPI(countryCode)
  if (dataAPI.status === 200) {
    dispatch(updateExtraInfos({ paymentSettings: [...dataAPI.data] }))
    const paymentSettings = dataAPI.data
    if (bankId) {
      const selectedItem = paymentSettings.find(item => item.id === bankId)
      if (selectedItem) dispatch(updateBookingAction({ bankTransfer: selectedItem }))
      else {
        const defaultItem = paymentSettings.find(item => item.settings.checkByDefault === true && item.method !== METHOD_CASH) || {}
        dispatch(updateBookingAction({ bankTransfer: defaultItem }))
      }
    } else {
      const defaultItem = paymentSettings.find(item => item.settings.checkByDefault === true && item.method !== METHOD_CASH) || {}
      dispatch(updateBookingAction({ bankTransfer: defaultItem }))
    }
    dispatch(calculate(() => {
      Utils.scrollTopAtStep3()
      handleClose()
      hideLoading()
    }))
  } else hideLoading()
}

export const getSpecialSettingsAction = (areaId) => async (dispatch) => {
  const specialSettingsData = await SettingAPI.getSettingsCustomerByArea(areaId, SECTION_TYPE_PAYMENT_LIST[SPECIAL_SETTINGS_AREA])
  if (specialSettingsData.status === 200) {
    const settingPayment = specialSettingsData.data
    dispatch(updateBookingAttributes({ special_settings: { ...settingPayment.special_settings } }))
    if (isCustomerEditBooking() || isBookAgain()) dispatch(
      bookingAgainDetailsActionsCreator.updateBookAgainDetails({ special_settings: { ...settingPayment.special_settings } })
    )
  }
}

export const getItemPaymentSettingsAction = (id, countryCode) => async (dispatch) => {
  const settingsData = await PaymentIntegrationAPI.getItemPaymentSettings(id, countryCode)
  if (!_.isEmpty(settingsData?.data)) dispatch(updateBookingAction({ bankTransfer: settingsData.data }))
}